import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import {
  GalleryContainer,
  MainPicture,
  NoImages,
  PictureStrip,
} from "../styled/Gallery.styled";
import { useBrowse } from "../hooks/useBrowse";

const fuseImageData = (pictures, imageDataSrc) => {
  return pictures.map(({ image, image_description: desc }, i) => {
    const imageData = imageDataSrc.nodes.find(({ relativePath }) => image === `/${relativePath}`); // prettier-ignore
    return { image, desc, idx: i, imageData };
  });
};

const Gallery = ({ pictures, imageData }) => {
  const images = fuseImageData(pictures, imageData);
  const [currentImg, setCurrentImg] = useBrowse(images.length);

  if (!images.length) return <NoImages>No Images Available</NoImages>;

  return (
    <GalleryContainer>
      <MainPicture>
        <GatsbyImage
          image={getImage(images[currentImg].imageData)}
          alt={images[currentImg].desc}
          objectFit="contain"
        />
      </MainPicture>
      <PictureStrip>
        {images.map(({ imageData, desc, image: key, idx }) => {
          const image = getImage(imageData);
          return (
            <GatsbyImage
              image={image}
              alt={desc}
              key={key}
              onClick={() => setCurrentImg(idx)}
            />
          );
        })}
      </PictureStrip>
    </GalleryContainer>
  );
};

export default Gallery;
