export const markdownByLang = (data, srcQuery = "page") => {
  const { nodes } = data[srcQuery];
  return nodes.reduce((langs, node) => {
    const { frontmatter, html } = node;
    const { lang, heading, description, category } = frontmatter;
    langs[lang] = { html, heading, description, category };
    return langs;
  }, {});
};

export const extractMarkdownProjects = (data, srcQuery = "projects") => {
  const { nodes } = data[srcQuery];

  const _projects = nodes.reduce((projects, node) => {
    const { title, heading, description, lang, pictures, thumbPosition } = node.frontmatter; // prettier-ignore

    const projectName = title.split("-").slice(0, -1).join("-");
    projects[projectName] = projects[projectName] ? projects[projectName] : {};
    projects[projectName].title = projectName;
    projects[projectName][lang] = { heading, description };

    const picturePaths = pictures?.map(({ image }) => image);
    const thumbnail = data.pics.nodes.find(({ relativePath }) => {
      return picturePaths?.includes("/" + relativePath);
    });

    if (thumbnail) projects[projectName].thumbnail = thumbnail;
    if (thumbPosition) projects[projectName].thumbPosition = thumbPosition;

    return projects;
  }, {});

  const projects = Object.values(_projects).map(p => p);
  return projects;
};

export const extractProjectPictures = (data, srcQuery = "page") => {
  const { nodes } = data[srcQuery];
  const pics = nodes.reduce((pictures, node) => {
    return node.frontmatter.pictures
      ? [...pictures, ...node.frontmatter.pictures]
      : pictures;
  }, []);
  return pics;
};
