import React, { useContext } from "react";
import { graphql } from "gatsby";

import { markdownByLang, extractProjectPictures } from "../utils/markdown";
import { LangContext } from "../context/LangContext";
import Layout from "../components/Layout";
import Gallery from "../components/Gallery";
import {
  ProjectContainer,
  ProjectInfo,
  ProjectInfoHeader,
  ProjectInfoStepBack,
  ProjectInfoStepBackBlock,
  ProjectInfoText,
} from "../styled/Project.styled";

const NavigateBackDictionary = {
  cs: {
    base: "Další",
    interiors: "Interiéry",
    paintings: "Malby",
    products: "Produkty",
  },
  en: {
    base: "Other",
    interiors: "Interiors",
    paintings: "Paintings",
    products: "Products",
  },
};

const getNavigateBackMessage = (lang, category) => {
  return `${NavigateBackDictionary[lang].base} ${NavigateBackDictionary[lang][category]}`;
};

const Show = ({ when, children }) => when && children;

const ProjectPage = ({ data }) => {
  const { lang } = useContext(LangContext);
  const content = markdownByLang(data);
  const pictures = extractProjectPictures(data);

  if (!content[lang]) {
    return <Layout>No content in English.</Layout>;
  }

  return (
    <Layout>
      <Show when={!content[lang]}>No content in this language mutation.</Show>
      <Show when={content[lang]}>
        <ProjectContainer>
          <ProjectInfo>
            <ProjectInfoHeader>
              <h1>{content[lang].heading}</h1>
              <h2>{content[lang].description}</h2>
            </ProjectInfoHeader>
            <ProjectInfoText
              dangerouslySetInnerHTML={{ __html: content[lang].html }}
            ></ProjectInfoText>
            <ProjectInfoStepBack to={`/${content[lang].category}`}>
              {getNavigateBackMessage(lang, content[lang].category)}
            </ProjectInfoStepBack>
          </ProjectInfo>
          <Gallery pictures={pictures} imageData={data.pics} />
          <ProjectInfoStepBackBlock to={`/${content[lang].category}`}>
            {getNavigateBackMessage(lang, content[lang].category)}
          </ProjectInfoStepBackBlock>
        </ProjectContainer>
      </Show>
    </Layout>
  );
};

export default ProjectPage;

export const query = graphql`
  query GetProject($project: String, $pictures: [String]) {
    page: allMarkdownRemark(
      filter: { frontmatter: { title: { regex: $project } } }
    ) {
      nodes {
        html
        frontmatter {
          description
          heading
          lang
          title
          category
          pictures {
            image
            image_description
          }
        }
      }
    }
    pics: allFile(filter: { relativePath: { in: $pictures } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 90)
        }
        relativePath
      }
    }
  }
`;
