import { useState } from "react";

export const useBrowse = (length, startIdx = 0) => {
  if (startIdx >= length) startIdx = 0;
  const [current, setCurrent] = useState(startIdx);
  const last = Number(length) - 1;

  const next = () => {
    setCurrent(current => (current + 1 > last ? 0 : current + 1));
  };

  const previous = () => {
    setCurrent(current => (current - 1 < 0 ? last : current - 1));
  };

  const setCurrentConstrained = idx => {
    idx = idx < 0 ? 0 : idx > last ? last : idx;
    setCurrent(idx);
  };

  return [current, setCurrentConstrained, next, previous];
};
