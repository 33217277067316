import styled from "styled-components";

export const GalleryContainer = styled.section`
  display: grid;
  grid-template-columns: 5fr 1fr;
  @media (max-width: 800px) {
    display: block;
    grid-template-columns: auto;
  }
`;

export const MainPicture = styled.div`
  padding: 10px 10px 10px 25px;
  display: grid;
  max-height: calc(100vh - 120px);
  @media (max-width: 800px) {
    max-height: auto;
    display: none;
  }
  img {
    background-color: rgba(0, 0, 0, 0.95);
    box-shadow: 0px 0px 14px 1px rgba(255, 255, 255, 0.4) inset;
    border-radius: 1px;
  }
`;

export const PictureStrip = styled.div`
  max-height: calc(100vh - 120px);
  overflow-y: auto;
  padding: 10px 0px;
  display: grid;
  gap: 3px;
  & * img {
    filter: saturate(0%);
    transition: all 1s ease;
    cursor: pointer;
  }
  & * img:hover {
    filter: saturate(200%);
  }
  @media (max-width: 800px) {
    overflow-y: hidden;
    max-height: none;
    & * img {
      filter: none;
      padding-bottom: 5px;
    }
  }
`;

export const NoImages = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
