import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

export const ProjectContainer = styled.main`
  background-image: url("/img/canvas.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: brightness(1.075);
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 10px 80px;
  max-height: calc(100vh - 100px);
  overflow: hidden;
  @media (max-width: 1000px) {
    padding: 10px 40px;
  }
  @media (max-width: 800px) {
    grid-template-columns: auto;
    grid-template-rows: auto 1fr 25px;
    overflow: auto;
  }
  @media (max-width: 540px) {
    padding: 10px 15px;
  }
`;

export const ProjectInfo = styled.section`
  position: relative;
  display: grid;
  grid-template-rows: min-content 1fr auto;
  padding: 2vmin 25px 10px 5px;
  max-height: calc(100vh - 120px);
  & > article > p {
    padding-bottom: 25px;
    &:last-of-type {
      padding-bottom: 0px;
    }
  }
  &::after {
    content: "";
    position: absolute;
    right: 0px;
    top: 2%;
    width: 1px;
    height: 96%;
    background-color: rgba(0, 0, 0, 0.075);
    @media (max-width: 800px) {
      width: 0px;
    }
  }
`;

export const ProjectInfoHeader = styled.header`
  & > h2 {
    position: relative;
    font-size: 1rem;
    font-style: italic;
    padding-bottom: 50px;
  }
  & > h2::after {
    position: absolute;
    content: "";
    height: 1px;
    width: 200px;
    background-color: rgba(0, 0, 0, 0.075);
    left: 0px;
    bottom: 25px;
  }
`;

export const ProjectInfoText = styled.article`
  text-align: top;
  padding-top: 10px;
  overflow: auto;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
`;

export const ProjectInfoStepBack = styled(props => <Link {...props} />)`
  padding-top: 10px;
  text-decoration: none;
  position: relative;
  color: rgba(0, 0, 0, 0.74);
  transition: 0.4s;
  transform-origin: left center;
  &:hover {
    transform: scale(1.05);
  }
  &:after {
    position: absolute;
    content: "";
    width: 0px;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.25);
    bottom: 0px;
    left: 5px;
    transition: 0.4s;
  }
  &:hover:after {
    width: 80px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;

export const ProjectInfoStepBackBlock = styled(props => <Link {...props} />)`
  text-decoration: none;
  position: relative;
  padding-bottom: 15px;
  color: rgba(0, 0, 0, 0.74);
  transition: 0.4s;
  transform-origin: left center;
  &:hover {
    transform: scale(1.05);
  }
  &:after {
    position: absolute;
    content: "";
    width: 0%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.25);
    bottom: 4px;
    left: 5px;
    transition: 0.4s;
  }
  &:hover:after {
    width: 80px;
  }
  @media (min-width: 800px) {
    display: none;
  }
`;

export const ProjectListHeading = styled.h3`
  position: relative;
  padding-top: 50px;
  padding-bottom: 20px;
  &::after {
    position: absolute;
    content: "";
    height: 1px;
    width: 40px;
    background-color: rgba(0, 0, 0, 0.7);
    bottom: 15px;
    left: 10px;
  }
`;

export const ProjectListContainer = styled.section`
  padding-top: 20px;
  list-style: none;
`;

export const ProjectListGrid = styled.ul`
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  gap: 20px;
`;
